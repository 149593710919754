import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Menu from './Global-Components/Menu';
import Footer from './Global-Components/Footer/Footer';
import Auth from './Pages/Auth/Auth';
import ResponsiveDrawer from './Global-Components/Sidebar';
import Home from './Pages/Home/Home';
import PitchDeckGen from './Pages/Pitch-Deck-gen/Pitch-Deck-gen';
import PitchQuestions from './Pages/Pitch-Deck-gen/PitchQuestions';
import Inbox from "./Pages/Account/Inbox/Inbox";
import PitchAnalyzer from './Pages/Pitch-Analyzer/Pitch-Analyzer';
import PitchEditor from './Pages/Pitch-Editor/Pitch-Editor';
import HomeNew from './Pages/Home-New/Home-New';
import PitchDashboard from './Pages/Pitch-Dashboard/Pitch-Dashboard';
import ScreeningDashboard from './Pages/Screening-Dashboard/ScreeningDashboard';
import Team from './Pages/Team/Team';
import Setting from './Pages/Setting/Setting';
import Pricing from './Pages/Pricing/Pricing';
import PitchTemplates from './Pages/Pitch-Templates/Pitch-Templates';
import PitchPDFanalyzer from './Pages/Pitch-Analyzer/Pitch-Analyzer-pdf';
import Testing from './Pages/Pitch-Analyzer/Testing';
import Testing1 from './Pages/Pitch-Analyzer/Testing1';
import LoadingLineWithPercentage from './Pages/Pitch-Analyzer/Loader';
import ContactUs from './Pages/ContactUs/ContactUs';
import BookDemo from './Pages/BookDemo/BookDemo';
import OnBoarding from './Pages/OnBoarding/OnBoarding';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService/TermsOfService';
import SidebarForAnalyzer from './Global-Components/SidebarForAnalyzer';
import ForgotPassword from './Pages/Auth/ForgotPassword';
import LiveDemo from './Pages/LiveDemo/LiveDemo';
import backend from './api';
// On boarding imports
import PitchDashboard2 from './On-boarding/Pages/Pitch-Dashboard/Pitch-Dashboard';
import Auth2 from './On-boarding/Pages/Auth/Auth';
import AuthInvitee from './On-boarding/Pages/Auth/AuthInvitee';

import ResponsiveDrawer2 from './On-boarding/Global-Components/Sidebar';
import Testing2 from './On-boarding/Pages/Pitch-Analyzer/Testing1';
import WrongURL from './Pages/WrongURL/WrongURL';
import DisabledUser from './Pages/DisabledUser/DisabledUser';
import InvitationExpired from './Pages/InvitationExpired/InvitationExpired';

import { setCompanyInfo } from './redux-store/auth/actions';
import { setUserData } from './redux-store/auth/actions';
import { authActions } from './redux-store';
import SelectFinal from './On-boarding/Pages/Pitch-Dashboard/Final-select';
import './App.css'
import SelectFinalDrawer from './On-boarding/Global-Components/Select-Final-Sidebar';
import OnboardingAuth from './On-boarding/Pages/Auth/onboarding';
import CustomerSetting from './On-boarding/Pages/Setting/Setting';
import sessionstorage from 'sessionstorage';
import RedirectAnalyzer from './On-boarding/Pages/Pitch-Analyzer/RedirectAnalyzer';
import { AnimatePresence } from 'framer-motion';
import OnBoardingSuccessPopup from './Global-Components/OnBoardingSuccessPopup/OnBoardingSuccessPopup';
import CompanyIndependent from './Pages/CompanyIndependent/CompanyIndependent';
import { Analytics } from '@vercel/analytics/react';
import Scoring from './Pages/Scoring/Scoring';
import Dashboard from './Scorer/Pages/Dashboard/Dashboard';
import ScorerDrawer from './Scorer/Global-Components/ScorerSidebar';
import Pipeline from './Pages/Pipeline/Pipeline';
import { ScrollToTop } from './Hooks/usePreventScrolling';
import Application from './On-boarding/Pages/Auth/Application';
import SubmittedDeck from './On-boarding/Pages/Auth/SubmittedDeck/SubmittedDeck';


const AnalyzerWithSideBar = (Component) => (
  <>
    <SidebarForAnalyzer component={<Component />} />
  </>
);

const RenderRouterWithSideBar = (Component) => (
  <>
    <ResponsiveDrawer component={<Component />} />
  </>
);

const selectFinalSideBar = (Component) => (
  <>
    <SelectFinalDrawer component={<Component />} />
  </>
);

const scorerSidebar = (Component) => (
  <>
    <ScorerDrawer component={<Component />} />
  </>
);


const RenderRouterWithSideBar2 = (Component) => (
  <>
    <ResponsiveDrawer2 component={<Component />} />
  </>
);

const RenderRouterWithMenuFooter = (Component) => (
  <>
    <Menu />
    <Component />
    <Footer />
  </>
);

const RenderRouterWithMenuOnly = (Component) => (
  <>
    <Menu />
    <Component />
  </>
);

const RenderRouterWithFooterOnly = (Component) => (
  <>
    <Component />
    <Footer />
  </>
);

const RenderRouterOnly = (Component) => (
  <>
    <Component />
  </>
);

const WrongURLRoute = () => (
  <Routes>
    <Route path="*" element={<WrongURL />} />
  </Routes>
)


const DisabledUserRoute = () => (
  <Routes>
    <Route path="*" element={<DisabledUser />} />
  </Routes>
)

const CustomerLoggedInRoutes = () => (
  <>
    <Routes>
      {/* <Route path="/chatbot/:id/" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/chatbot" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/pitchgenerator" element={RenderRouterWithMenuOnly(PitchDeckGen)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} />
      <Route path="/pitcheditor" element={RenderRouterWithSideBar(PitchEditor)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} />
      <Route path="/templates" element={RenderRouterWithSideBar2(PitchTemplates)} />
      <Route path="/testing" element={RenderRouterWithSideBar(Testing)} />
      <Route path="/testing1" element={RenderRouterWithSideBar(Testing1)} />
      <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/contact-us" element={RenderRouterWithFooterOnly(ContactUs)} />
      <Route path="/book-demo" element={RenderRouterWithMenuFooter(BookDemo)} />
      <Route path="/privacy-policy" element={RenderRouterWithMenuFooter(PrivacyPolicy)} />
      <Route path="/terms-of-service" element={RenderRouterWithMenuFooter(TermsOfService)} />
      <Route path="/wrongurl" element={<WrongURL />} />

      <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />

     
      <Route path="/" element={<ResponsiveDrawer2 />} />
      <Route path="/dashboard" element={<ResponsiveDrawer2 />} />
      <Route path="/setting" element={RenderRouterWithSideBar2(CustomerSetting)} />
      <Route path="/select-final" element={selectFinalSideBar(SelectFinal)} />
      <Route path="/analyzer" element={RenderRouterWithSideBar2(RedirectAnalyzer)} />
      <Route path="/analyzer/:id" element={<Testing2 />} /> */}

      <Route path="/onboarding" element={<OnboardingAuth />} />
      <Route path="/application" element={<Application />} />
      <Route path="/submitted" element={<SubmittedDeck />} />
      <Route path="*" element={<OnboardingAuth />} />


    </Routes>
  </>
);

const CustomerNotOnboardedRoutes = () => (
  <>
    <Routes>
      <Route path="/onboarding" element={<OnboardingAuth />} />
      <Route path="*" element={<OnboardingAuth />} />
      <Route path="/application" element={<Application />} />

      <Route path="/submitted" element={<SubmittedDeck />} />

    </Routes>
  </>
);

// const CustomerSubmittedDeckRoutes = () => (
//   <>
//     <Routes>
//       <Route path="/submitted" element={<SubmittedDeck />} />
//       <Route path="*" element={<SubmittedDeck />} />
//     </Routes>
//   </>
// );



const ScorerLoggedInRoutes = () => (
  <>
    <Routes>
      <Route path="/scorer" element={scorerSidebar(Dashboard)} />
      <Route path="/analyzer" element={<RedirectAnalyzer />} />
      <Route path="/analyzer/:id" element={<Testing1 />} />
      <Route path="/contact-us" element={RenderRouterWithFooterOnly(ContactUs)} />

      <Route path="*" element={scorerSidebar(Dashboard)} />
    </Routes>
  </>
)

const CompanyLoggedInRoutes = () => (
  <>
    <Routes>
      {/* <Route path="/" element={RenderRouterWithSideBar(PitchDashboard)} /> */}
      <Route path="/chatbot/:id/" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/chatbot" element={RenderRouterWithSideBar(Inbox)} />
      <Route path="/pitchgenerator" element={RenderRouterWithMenuOnly(PitchDeckGen)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} />
      {/* <Route path="/analyzer" element={AnalyzerWithSideBar(Testing1)} />
      <Route path="/analyzer/:id" element={AnalyzerWithSideBar(Testing1)} /> */}
      <Route path="/pitcheditor" element={RenderRouterWithSideBar(PitchEditor)} />
      <Route path="/pitchquestions" element={<PitchQuestions />} />
      {/* <Route path="/dashboard" element={RenderRouterWithSideBar(PitchDashboard)} /> */}
      <Route path="/templates" element={RenderRouterWithSideBar(PitchTemplates)} />
      <Route path="/testing" element={RenderRouterWithSideBar(Testing)} />

      <Route path="/testing1" element={RenderRouterWithSideBar(Testing1)} />

      <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/contact-us" element={RenderRouterWithFooterOnly(ContactUs)} />
      <Route path="/book-demo" element={RenderRouterWithMenuFooter(BookDemo)} />
      <Route path="/privacy-policy" element={RenderRouterWithMenuFooter(PrivacyPolicy)} />
      <Route path="/terms-of-service" element={RenderRouterWithMenuFooter(TermsOfService)} />
      {/* <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} /> */}

      {/* Company roouts */}
      <Route path="/demo" element={RenderRouterOnly(LiveDemo)} />
      <Route path="/" element={RenderRouterWithSideBar(ScreeningDashboard)} />
      <Route path="/dashboard" element={RenderRouterWithSideBar(ScreeningDashboard)} />
      <Route path="/screening-dashboard" element={RenderRouterWithSideBar(ScreeningDashboard)} />
      <Route path="/team" element={RenderRouterWithSideBar(Team)} />
      <Route path="/scoring" element={RenderRouterWithSideBar(Scoring)} />
      <Route path="/pipeline" element={RenderRouterWithSideBar(Pipeline)} />
      <Route path="/setting" element={RenderRouterWithSideBar(Setting)} />
      <Route path="/pricing" element={RenderRouterWithSideBar(Pricing)} />
      <Route path="/analyzer" element={<RedirectAnalyzer />} />


      <Route path="/analyzer/:id" element={<Testing1 />} />

      {/* <Route path="/analyzer/:id" element={<ImageAi />} /> */}


      <Route path="*" element={RenderRouterWithSideBar(ScreeningDashboard)} />
      <Route path="/wrongurl" element={<WrongURL />} />
      <Route path="/onboarding" element={<OnboardingAuth />} />

      <Route path="/scorer" element={scorerSidebar(Dashboard)} />

    </Routes>
  </>
);

const NotLoggedInRoutes = () => (
  <>
    <Routes>
      <Route path="/" element={<Auth2 />} />
      {/* <Route path="/login" element={<Auth />} /> */}
      <Route path="/:hashcode/:senderId/:inviteeId/:parentcompanyId" element={<AuthInvitee />} />

      <Route path="/createpassword/:id" element={<ForgotPassword />} />
      <Route path="/pitchgenerator" element={RenderRouterWithMenuOnly(PitchDeckGen)} />
      <Route path="/contact-us" element={RenderRouterWithMenuFooter(ContactUs)} />
      <Route path="/book-demo" element={RenderRouterWithMenuFooter(BookDemo)} />
      <Route path="/privacy-policy" element={RenderRouterWithMenuFooter(PrivacyPolicy)} />
      <Route path="/terms-of-service" element={RenderRouterWithMenuFooter(TermsOfService)} />
      <Route path="/home" element={RenderRouterWithMenuFooter(HomeNew)} />
      <Route path="/demo" element={RenderRouterOnly(LiveDemo)} />
      <Route path="*" element={<Auth2 />} />
      {/* OnBoardingNotLoggedInRoutes */}
      <Route path="/login" element={<Auth2 />} />
      <Route path="/on-boarding" element={<OnBoarding />} />
      <Route path="/wrongurl" element={<WrongURL />} />

      <Route path="/invitationexpired" element={<InvitationExpired />} />

      <Route path="/accessdenied" element={<DisabledUser />} />


    </Routes>
  </>
);

const OnBoardingNotLoggedInRoutes = () => (
  <>
    <Routes>
      <Route path="*" element={RenderRouterWithMenuFooter(HomeNew)} />
    </Routes>
  </>
);

function AppRoutes() {
  const { user } = useSelector((state) => state.auth);

  const userId = user?._id

  const companyInfo = useSelector((state) => state.companyInfo?.info);
  const [assignRoutes, setAssignRoutes] = useState(false);
  const isProduction = process.env.NODE_ENV === 'production';
  const [subdomain, setSubdomain] = useState('');
  const [isValidSubdomain, setIsValidSubdomain] = useState(true); // Default to true until verified
  const dispatch = useDispatch();


  useEffect(() => {
    const fetchUserData = async () => {
      const params = new URLSearchParams(window.location.search);
      const userDataEncoded = params.get('userData');
      if (userDataEncoded && !user) {
        try {
          const userData = JSON.parse(decodeURIComponent(userDataEncoded));
          console.log(userData); // Use the user object as needed
          localStorage.setItem("user", JSON.stringify(userData))
          localStorage.setItem("popup", true)
          sessionstorage.setItem("navBtn", "Screening Dashboard")

          await dispatch(setUserData(userData));

        } catch (error) {
          console.error('Failed to parse user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Use the userID directly in the URL as a path parameter
        const resp = await backend.put(`/api/user/${userId}`);
        console.log(resp);

        // Check if the response is successful
        if (resp.status === 200 && resp.data) {
          console.log(resp.data); // Handle the user data here
          if (resp?.data.data?.status == "disabled") {
            handleLogout();
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error); // Handle error
      }
    };

    fetchData(); // Call the fetch function


  }, []); // Dependency array includes userID

  const handleLogout = async () => {
    localStorage.clear();
    sessionStorage.clear();
    await dispatch(authActions.logout());
    window.location.href = `https://${companyInfo?.companyName}.pitchscreener.ai/accessdenied`; // Redirect to the access denied page
  };


  // useEffect(() => {
  //   const checkUserAndCompanyInfo = async () => {
  //     if (isProduction) {
  //       if (user && companyInfo) {
  //         if (user.companyId === companyInfo._id) {
  //           setAssignRoutes(true)
  //           setIsValidSubdomain(true)
  //           localStorage.setItem('validUser', true)
  //         } else {
  //           setAssignRoutes(false)
  //           localStorage.clear();
  //           await dispatch(authActions.logout());
  //           setIsValidSubdomain(false);
  //         }
  //       }
  //     }
  //   };

  //   checkUserAndCompanyInfo();
  // }, [user, companyInfo])



  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = window.location.hostname;
        const parts = url.split('.');
        if (parts.length >= 3) {
          // Third-level domain is the subdomain (e.g., 'subdomain.domain.com')
          const companyName = parts[0];
          setSubdomain(companyName);

          // Check if companyName exists in database
          const response = await backend.get('/onboarding/companyname', {
            params: { companyName: companyName }
          });
          console.log(response.data[0])
          // If response.data is not empty, companyName exists in database
          if (response.data.length > 0) {
            await dispatch(setCompanyInfo(response.data[0]))
            setIsValidSubdomain(true); // Valid subdomain
          } else {
            setIsValidSubdomain(false); // Invalid subdomain
          }
        } else {
          // No subdomain (e.g., 'domain.com')
          setSubdomain('');
          setIsValidSubdomain(true); // Reset to true for non-subdomain URLs
        }

        if (process.env.NODE_ENV === 'development') {
          try {
            const companyName = 'devzona';

            const response = await backend.get('/onboarding/companyname', {
              params: { companyName: companyName }
            });

            console.log(response.data[0]);

            // If response contains data, dispatch to Redux
            if (response.data.length > 0) {
              dispatch(setCompanyInfo(response.data[0]));
              console.log('Fetched Devzona company data successfully!');
            } else {
              console.error('No company data found for Devzona');
            }
          } catch (error) {
            console.error('Error fetching Devzona data:', error);
          }
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setIsValidSubdomain(false); // Set to false on error
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs once on component mount

  return (
    <>
      <BrowserRouter>
      <ScrollToTop />
        {isProduction ? (
          !isValidSubdomain ? (
            <WrongURLRoute />
          ) : (
            user && user.status == "disabled" ? (
              <DisabledUserRoute />
            ) : user && user?.role === "customer" && user.status == "active" ? (
              user.customerOnboarded ? <CustomerLoggedInRoutes key={user.customerOnboarded} /> : <CustomerNotOnboardedRoutes key={user.customerOnboarded} />
            ) : user && user?.role === "company" && user.status == "active" ? (
              <>
                <CompanyLoggedInRoutes />
                <CompanyIndependent />
              </>
            ) : user && user?.role === "judge" && user.status == "active" ? (
              <ScorerLoggedInRoutes />
            ) : (
              <NotLoggedInRoutes />
            )
          )
        ) : (
          user && user.status == "disabled" ? (
            <DisabledUserRoute />
          ) : (
            user && user?.role === "customer" && user.status == "active" ? (
              user.customerOnboarded ? <CustomerLoggedInRoutes key={user.customerOnboarded} /> : <CustomerNotOnboardedRoutes key={user.customerOnboarded} />
            ) : user && user?.role === "company" && user.status == "active" ? (
              <>
                <CompanyLoggedInRoutes />
                <CompanyIndependent />
              </>
            ) : user && user?.role === "judge" && user.status == "active" ? (
              <ScorerLoggedInRoutes />
            ) : (
              <NotLoggedInRoutes />
            )
          )
        )}
        <Analytics />
      </BrowserRouter>
    </>
  );
}

export default AppRoutes;
