import LogoutIcon from "@mui/icons-material/Logout";
import React, { useEffect, useState } from "react";
import { LinearProgress, Paper, Button, Modal } from '@mui/material';
import StructureIcon from '@mui/icons-material/AccountBalance';
import MarketIcon from '@mui/icons-material/ShowChart';
import BusinessIcon from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { authActions } from "../redux-store";
import Typography from "@mui/material/Typography";
import TelegramIcon from "@mui/icons-material/Telegram";
import NotesIcon from "@mui/icons-material/Notes";
import FolderIcon from "@mui/icons-material/Folder";
import backend from "../api";
import HistoryIcon from "@mui/icons-material/History";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import sessionstorage from "sessionstorage";
import { setChatHistoryId } from "../redux-store/auth/actions";
import { setSidebar } from "../redux-store/auth/actions";
import DashboardIcon from '@mui/icons-material/Dashboard';
import './Sidebar.css'
import Classes from './Sidebar.module.css'
import UpgradePopup from "./UpgradePopup/UpgradePopup";
import Color from 'color';

const drawerWidth = 265;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto', // Adjust width as needed
  maxWidth: '70vw', // Maximum width of the modal is 80% of the viewport width
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto', // Enable scrolling if content is too long
  maxHeight: '90vh', // Maximum height is 80% of the viewport height
  margin: '5vh', // Adds margin to the top & bottom of the modal (5% of the viewport height)
  borderRadius: "15px",

};

const AnalyzerIcon = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_1151511"
    >
      <path d="M351.332031 153.660156v-88.453125c0-35.953125-29.253906-65.207031-65.210937-65.207031h-220.917969c-35.953125 0-65.203125 29.253906-65.203125 65.207031v147.714844c0 30.796875 21.457031 56.671875 50.203125 63.46875v36.949219c0 6.066406 3.65625 11.535156 9.261719 13.859375 1.855468.769531 3.804687 1.140625 5.738281 1.140625 3.902344 0 7.738281-1.523438 10.609375-4.394532l45.8125-45.8125h9.046875v-29.265624c0-52.5 42.707031-95.207032 95.203125-95.207032zm0 0" />
      <path d="M446.792969 183.660156h-220.917969c-35.953125 0-65.203125 29.25-65.203125 65.207032v147.714843c0 35.957031 29.25 65.207031 65.203125 65.207031h164.496094l45.8125 45.816407c2.871094 2.871093 6.707031 4.394531 10.613281 4.394531 1.929687 0 3.878906-.371094 5.734375-1.140625 5.605469-2.324219 9.261719-7.792969 9.261719-13.859375v-36.949219c28.75-6.792969 50.207031-32.671875 50.207031-63.46875v-147.714843c0-35.957032-29.253906-65.207032-65.207031-65.207032zm-46.597657 114.167969-71.007812 71.007813c-2.929688 2.929687-6.769531 4.390624-10.605469 4.390624-3.839843 0-7.679687-1.460937-10.605469-4.390624l-35.507812-35.503907c-5.859375-5.855469-5.859375-15.355469 0-21.214843 5.855469-5.855469 15.355469-5.855469 21.210938 0l24.902343 24.898437 60.398438-60.402344c5.859375-5.855469 15.355469-5.855469 21.214843 0 5.859376 5.859375 5.859376 15.359375 0 21.214844zm0 0" />
    </svg>
  );
};

const TeamIcon = (props) => {
  return (
    <svg {...props} id="fi_8215621" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m17.233 15.328a4.773 4.773 0 0 0 -4.7-4.078h-1.064a4.773 4.773 0 0 0 -4.7 4.078l-.51 3.566a.75.75 0 0 0 .213.636c.2.2 1.427 1.22 5.53 1.22s5.327-1.016 5.53-1.22a.75.75 0 0 0 .213-.636z"></path><path d="m7.56 11.8a5.7 5.7 0 0 0 -1.78 3.39l-.37 2.56c-2.97-.02-3.87-1.1-4.02-1.32a.739.739 0 0 1 -.13-.56l.22-1.24a4.093 4.093 0 0 1 6.08-2.83z"></path><path d="m22.74 15.87a.739.739 0 0 1 -.13.56c-.15.22-1.05 1.3-4.02 1.32l-.37-2.56a5.7 5.7 0 0 0 -1.78-3.39 4.093 4.093 0 0 1 6.08 2.83z"></path><path d="m7.73 9.6a2.714 2.714 0 0 1 -2.23 1.15 2.75 2.75 0 1 1 1.65-4.95 4.812 4.812 0 0 0 -.15 1.2 5.01 5.01 0 0 0 .73 2.6z"></path><path d="m21.25 8a2.748 2.748 0 0 1 -2.75 2.75 2.714 2.714 0 0 1 -2.23-1.15 5.01 5.01 0 0 0 .73-2.6 4.812 4.812 0 0 0 -.15-1.2 2.75 2.75 0 0 1 4.4 2.2z"></path><circle cx="12" cy="7" r="3.75"></circle></svg>
  )
}

const ScoringIcon = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
  )
}

const Pipeline = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="fi_17128490">
      <g id="Layer">
        <path d="M13.32,24.94A.87.87,0,0,1,13,25H8a1,1,0,0,1,0-2h5c0-.17,0-.33,0-.5A9.48,9.48,0,0,1,14.75,17H8a1,1,0,0,1,0-2h8.67a9.43,9.43,0,0,1,5.83-2,9.29,9.29,0,0,1,1.5.12V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V27a3,3,0,0,0,3,3H16.67A9.53,9.53,0,0,1,13.32,24.94ZM8,7H18a1,1,0,0,1,0,2H8A1,1,0,0,1,8,7Zm0,4H18a1,1,0,0,1,0,2H8a1,1,0,0,1,0-2Zm14.5,4A7.5,7.5,0,1,0,30,22.5,7.5,7.5,0,0,0,22.5,15ZM22,21.6l1.45.34A2.19,2.19,0,0,1,25,24.39a2.6,2.6,0,0,1-1.79,2.07v0a.75.75,0,0,1-1.5,0v0a2.58,2.58,0,0,1-1.82-2,.75.75,0,1,1,1.47-.28,1.1,1.1,0,0,0,2.16,0,.68.68,0,0,0-.51-.76l-1.45-.34A2.19,2.19,0,0,1,20,20.61a2.6,2.6,0,0,1,1.79-2.07v0a.75.75,0,0,1,1.5,0v0a2.58,2.58,0,0,1,1.82,2,.75.75,0,1,1-1.47.28,1.1,1.1,0,0,0-2.16,0A.68.68,0,0,0,22,21.6Z" />
      </g>
    </svg>
  );
};

// The rest of your components remain unchanged

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navBtn = sessionstorage.getItem("navBtn")
  const [selectedButton, setSelectedButton] = React.useState(navBtn ? navBtn : "Pitch Deck Dashboard");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  console.log(user);
  const [loading, setLoading] = React.useState(false);
  const BotID = localStorage.getItem('projectID');
  const ProjectName = localStorage.getItem('projectName');
  const ProjectID = localStorage.getItem("projectID")

  const [pitchDeckScore, setPitchDeckScore] = useState('');
  const [deckStructureScore, setDeckStructureScore] = useState('');
  const [marketPotentialScore, setMarketPotentialScore] = useState('');
  const [businessModelScore, setBusinessModelScore] = useState('');

  const [deckStructureParagraph, setDeckStructureParagraph] = useState('');
  const [marketPotentialParagraph, setMarketPotentialParagraph] = useState('');
  const [businessModelParagraph, setBusinessModelParagraph] = useState('');
  const sideBarValue = useSelector((state) => state.sidebar.sidebarValue);
  const deckId = useSelector((state) => state.deckId.deckId);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpgradePopup, setopenUpgradePopup] = useState(false);
  const closeUpgradePopup = () => {
    setopenUpgradePopup(false);
  }

  const navigateTo = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const NavigateToBots = async () => {
    sessionstorage.setItem("navBtn", "")
    const id = localStorage.getItem('projectID');
    await navigateTo(`/chatbots/${id}`)
  };

  console.log(sideBarValue)
  //  useEffect(() => {


  //   // Update component state based on sideBarValue
  //   if (sideBarValue) {
  //     // Adjust these according to the actual structure of sideBarValue
  //     setPitchDeckScore(sideBarValue?.pitchDeckScore);
  //     setDeckStructureScore(sideBarValue?.deckStructureScore);
  //     setMarketPotentialScore(sideBarValue?.marketPotentialScore);
  //     setBusinessModelScore(sideBarValue?.businessModelScore);
  //     setDeckStructureParagraph(sideBarValue?.deckStructureParagraph);
  //     setMarketPotentialParagraph(sideBarValue?.marketPotentialParagraph);
  //     setBusinessModelParagraph(sideBarValue?.businessModelParagraph);
  //   }


  // }, [sideBarValue]); // Re-run when sideBarValue changes


  const saveChat = async () => {
    const userId = user._id

    try {
      const body = {
        UserId: userId,
        projectId: ProjectID
      };

      const response = await backend.post("/chats", body);
      // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      dispatch(setChatHistoryId(RouteID))
      await UpdateProjectById(RouteID)
      // navigateTo(`/chat/${ProjectID}/${RouteID}`)

    } catch (error) {
      console.error(error);
      // toast.error("Error saving story");
    }

  };

  const UpdateProjectById = async (newChatData) => {

    console.log(newChatData)

    const id = await user?._id;
    try {
      // Fetch the existing project data
      setLoading(true);
      const response = await backend.get(`/mainfolder/${ProjectID}`);
      console.log(response?.data)
      const existingProjectData = response?.data;

      // Modify the chats array by merging new data with the existing data
      const updatedChats = [...existingProjectData.chats, newChatData];

      // Prepare the updated project body
      const body = {
        ...existingProjectData,
        chats: updatedChats,
      };
      // console.log(typeof(ProjectID))
      // Make the PUT request to update the project
      const resp = await backend.put(`/mainfolder/${ProjectID}`, body);

      console.log(resp)
      setLoading(false);
      // Optionally, you can return the updated project data
      navigateTo(`/chat/${ProjectID}/${newChatData}`)
    } catch (error) {
      // Handle error
      console.error("Error updating project:", error.message);
      throw error;
    }
  };

  const s3FileUrl = localStorage.getItem('pitch');


  useEffect(() => {
    if (selectedButton === 'Business Chatbot') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/chatbot`)
    }
    if (selectedButton === 'Pitch Deck Analyzer') {
      if (s3FileUrl) {
        sessionstorage.setItem("navBtn", selectedButton)

        dispatch(setChatHistoryId(''))
        // navigateTo(`/analyzer`)
        navigateTo(`/analyzer/${deckId}`)
      } else {
        sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")
        setSelectedButton("Pitch Deck Dashboard")

        dispatch(setChatHistoryId(''))
        navigateTo(`/dashboard`)
      }

    }
    if (selectedButton === 'Chat') {
      // navigateTo('/chat')
      sessionstorage.setItem("navBtn", selectedButton)
      saveChat();
    }

    if (selectedButton === 'Pitch Deck Editor') {
      sessionstorage.setItem("navBtn", selectedButton)
      dispatch(setChatHistoryId(''))
      navigateTo(`/pitcheditor/`)
    }
    if (selectedButton === 'Pitch Deck Dashboard') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/dashboard`)
    }
    if (selectedButton === 'Screening Dashboard') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/screening-dashboard`)
    }
    if (selectedButton === 'Team') {
      console.log('Team clicked');
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/team`)
    }
    if (selectedButton === 'Scoring') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/scoring`)
    }
    if (selectedButton === 'Pipeline') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/pipeline`)
    }
    if (selectedButton === 'Setting') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/setting`)
    }
    if (selectedButton === 'billing') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/billing`)
    }
    if (selectedButton === 'Pricing') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/pricing`)
    }
    if (selectedButton === 'Pitch Deck Templates') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/templates/`)
    }
    if (selectedButton === 'Testing') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/testing/`)
    }
    if (selectedButton === 'Testing-1') {
      sessionstorage.setItem("navBtn", selectedButton)
      navigateTo(`/testing1/`)
    }


  }, [selectedButton])

  const handleLogout = async () => {
    localStorage.clear();
    sessionstorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    // alert("Signed out successfully")
  };

  const handleSettingClick = () => {
    setSelectedButton('Setting');
    navigateTo('/setting');
  }

  const handlePricingClick = () => {
    setSelectedButton('Pricing');
    navigateTo('/pricing');
  }

  const onBoarding = useSelector((state) => state.companyInfo.info);
  const convertToRgba = (colorInput, opacity) => {
    try {
      const color = Color(colorInput);
      return color.alpha(opacity).rgb().string();
    } catch (error) {
      console.error("Invalid color format:", colorInput);
      return `rgba(0, 0, 0, ${opacity})`;
    }
  };

  const handleHomeClick = async () => {
    // sessionstorage.setItem("navBtn", 'Screening Dashboard')
    setSelectedButton("Screening Dashboard")
    // sessionstorage.setItem("refresh", 'yes')
    // navigateTo("/");
    // alert("Signed out successfully")
  };

  const ProgressWithLabel = ({ value, label }) => (
    <Box display="flex" alignItems="center">
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{label}</Typography>
      </Box>
      <Box width="100%" mx={2}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );

  const ScoreProgress = ({ value, IconComponent, label }) => {
    const numericValue = parseFloat(value); // Convert string value to number

    // Determine color based on value
    let color;
    if (numericValue >= 100) {
      color = 'green'; // Strong
    } else if (numericValue >= 80) {
      color = 'lightgreen'; // Low strong
    } else {
      color = '#F44336'; // Weak or normal
    }

    // Determine the color of the LinearProgress based on the score
    const progressColor = (value) => {
      if (value >= 100) return 'green'; // Strong
      else if (value >= 80) return 'lightgreen'; // Low strong
      else return '#F44336'; // Weak or normal
    };

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <img src={`/icons/${IconComponent}`} style={{ width: "23px", height: "23px", paddingRight: "1rem" }} />
          <p style={{ color: "black", fontWeight: "600" }}>{label}</p>
        </div>
        <Box display="flex" alignItems="center" >
          <Box width="100%" mr={1} >
            <LinearProgress
              variant="determinate"
              value={numericValue}
              sx={{
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: progressColor(numericValue),
                },
                backgroundColor: '#eee', // Background color of the progress bar
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(numericValue)}/100`}</Typography>
          </Box>
        </Box>
      </div>
    );
  };
  const drawer = (
    <div style={{
      background: "white",
      // color: "rgba(255, 255, 255, 0.6)",
      minHeight: "100vh",
      // width: "360px", // Set a fixed width for the sidebar
    }}
      className="element"
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <a className={Classes.logo} onClick={() => handleHomeClick()}>
          <img src={onBoarding?.logo} alt="logo" />
        </a>
      </Toolbar>

      <div className="li-items545" >
        <List>
          {/* "Pitch Deck Dashboard", */}
          {[
            "Screening Dashboard",
            "Scoring",
            "Pipeline",
            "Team",
          ].map((text, index) => (
            <ListItem key={text} style={{
              width: "100%",
              // background:
              //   selectedButton === text
              //     ? convertToRgba(onBoarding.color, 0.10)
              //     : "transparent",
              // borderRight: selectedButton === text ? `6px solid ${onBoarding.color}` : "",
              // height:"50px",
              padding: "0.45rem 0.5rem"

            }}>
              <ListItemButton
                onClick={() => { index === 8 && !s3FileUrl ? setSelectedButton("Pitch Deck Dashboard") : setSelectedButton(text) }}
                style={{
                  background: "transparent",
                  color: "black",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "0rem 1rem"
                }}
                disabled={index === 10 && !s3FileUrl ? true : false}
                disableRipple // Disables the ripple effect
                disableTouchRipple // Disables the ripple effect on touch devices
              >
                <ListItemIcon style={{ padding: "0rem 0rem" }}>
                  {
                    index === 0 ?
                      (
                        <svg fill={selectedButton === text ? onBoarding.color : 'black'} height="19px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
                        </svg>
                      )
                      :
                      index === 1 ?
                        (<>
                          <ScoringIcon height="18px" fill={selectedButton === text ? onBoarding.color : 'black'} />
                        </>)
                        :
                        index === 2 ?
                          (<>
                            <Pipeline height="18px" fill={selectedButton === text ? onBoarding.color : 'black'} />
                          </>)
                          :
                          index === 3 ?
                            (
                              <TeamIcon height="20px" fill={selectedButton === text ? onBoarding.color : 'black'} />
                            )
                            :
                            index === 4 ?
                              (
                                <AnalyzerIcon height="18px" fill={selectedButton === text ? onBoarding.color : 'black'} />
                              )
                              :
                              (
                                <FolderSpecialIcon />
                              )}
                </ListItemIcon>
                <Typography
                  style={{
                    fontWeight: selectedButton === text ? "700" : "700",
                    fontSize: "15px",
                    color: selectedButton === text ? onBoarding.color : 'black',
                    marginLeft: "-1.8rem"
                  }}
                  className={Classes.manrope}
                >
                  {text === 'Screening Dashboard' ? 'Dashboard' : text === 'Pitch Deck Dashboard' ? 'Deck Analyzer' : text}
                </Typography>
                {(text === "Scoring" || text === "Pipeline") && (onBoarding.subscription === "free") &&
                  <svg id="fi_2476413" enable-background="new 0 0 32 32" style={{ marginLeft: '10px' }} fill='black' height="15px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m27.488 23-.513 2.225c-.105.454-.509.775-.975.775h-20c-.466 0-.87-.321-.975-.775l-.513-2.225z"></path><path d="m29.975 12.225-2.025 8.775h-23.9l-2.025-8.775c-.089-.387.059-.791.378-1.028.32-.237.749-.262 1.093-.065l6.189 3.537 5.482-8.223c.179-.268.475-.434.796-.446.324-.014.629.132.826.386l6.429 8.266 5.227-3.484c.341-.226.786-.224 1.123.009.338.233.498.649.407 1.048z"></path></svg>
                }
              </ListItemButton>
            </ListItem>
          ))}

        </List>
        <br />
      </div>
      <div style={{ position: "absolute", bottom: 0, width: '100%' }}>
        {openUpgradePopup && <UpgradePopup closeUpgradePopup={closeUpgradePopup} closeable={true} />}

        <div className={Classes.premiumBox} >
          <div className={Classes.flexAlign} >
            <h5 className={Classes.upgradeTitle} >You’re on
              {(onBoarding?.subscription === 'monthly_basic' || onBoarding?.subscription === 'yearly_basic') ?
                ' Pro!' :
                (onBoarding?.subscription === 'monthly_pro' || onBoarding?.subscription === 'yearly_pro') ?
                  ' Enterprise!' : ' Free!'
              }
            </h5>
            <svg
              id="fi_3064889"
              enableBackground="new 0 0 24 24"
              height="15px"
              width="15px"
              viewBox="0 0 24 24"
              fill={onBoarding?.color}
            >
              <path d="m23.508.003c-4.685-.084-10.028 2.365-13.41 6.164-3.232.061-6.379 1.386-8.696 3.703-.135.133-.183.332-.124.512.06.181.216.312.404.339l3.854.552-.476.533c-.177.198-.168.499.02.687l6.427 6.427c.097.097.225.146.354.146.119 0 .238-.042.333-.127l.533-.476.552 3.854c.027.188.175.326.354.386.046.015.094.022.143.022.142 0 .287-.062.387-.161 2.285-2.285 3.61-5.432 3.671-8.664 3.803-3.389 6.272-8.73 6.163-13.409-.007-.266-.222-.481-.489-.488zm-4.608 8.632c-.487.487-1.127.731-1.768.731s-1.281-.244-1.768-.731c-.974-.975-.974-2.561 0-3.536.975-.975 2.561-.975 3.536 0s.975 2.562 0 3.536z"></path>
              <path d="m2.724 16.905c-1.07 1.07-2.539 5.904-2.703 6.451-.053.176-.004.367.125.497.096.096.223.147.354.147.048 0 .096-.007.144-.021.547-.164 5.381-1.633 6.451-2.703 1.205-1.205 1.205-3.166 0-4.371-1.206-1.205-3.166-1.204-4.371 0z"></path>
            </svg>
          </div>
          {(onBoarding?.subscription === 'free' || onBoarding?.subscription === 'monthly_basic' || onBoarding?.subscription === 'yearly_basic') &&
            <h5 className={Classes.upgradeText} >
              Upgrade to increase members <br />
              and company limits
            </h5>
          }
          <div className={`${Classes.premiumBtn} ${Classes.premiumBtn2}`} >
            {(onBoarding?.subscription === 'monthly_pro' || onBoarding?.subscription === 'yearly_pro') ?
              <span className={Classes.flexAlignEnterprise}> {onBoarding?.total_users} / <span>∞</span> companies</span>
              :
              <span style={{ color: 'black' }} > {onBoarding?.total_users} / {onBoarding?.remaining_users} companies</span>
            }
          </div>
          {(onBoarding?.subscription !== 'yearly_pro' && onBoarding?.subscription !== 'monthly_pro') ?
            <div onClick={handlePricingClick} className={Classes.premiumBtn} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }} >
              <span className={Classes.upgradeSpan} >
                Upgrade
              </span>
            </div>
            :
            <></>
          }
        </div>

        <div>
          <Divider style={{ margin: '0 9px 0 9px', marginBottom: "1rem" }} />
          <div className="li-items545" >
            <List>
              <ListItem style={{
                width: "100%", background:
                  selectedButton === 'setting'
                    ? convertToRgba(onBoarding.color, 0.10)
                    : "transparent",
                borderRight: selectedButton === 'setting' ? `6px solid ${onBoarding.color}` : "",
                // height:"50px",
                padding: "0.45rem 0.5rem"
              }}
                onClick={handleSettingClick}
              >
                <ListItemButton
                  style={{
                    background: "transparent",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 1rem"
                  }}
                  disableRipple // Disables the ripple effect
                  disableTouchRipple // Disables the ripple effect on touch devices
                >
                  <ListItemIcon style={{ padding: "0rem 0rem" }}>
                    <svg fill={selectedButton === 'setting' ? onBoarding.color : 'black'} height="17px" xmlns="http://www.w3.org/2000/svg" cursor="pointer" viewBox="0 0 512 512">
                      <path fill="#000000" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: selectedButton === 'Setting' ? "700" : "500",
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                      color: selectedButton === 'setting' ? onBoarding.color : 'black',
                      marginLeft: "-1.8rem"
                    }}
                  >
                    Settings
                  </Typography>
                </ListItemButton>

              </ListItem>
              <ListItem style={{
                width: "100%", background:
                  selectedButton === 'pricing'
                    ? convertToRgba(onBoarding.color, 0.10)
                    : "transparent",
                borderRight: selectedButton === 'pricing' ? `6px solid ${onBoarding.color}` : "",
                // height:"50px",
                padding: "0.45rem 0.5rem"
              }}
                onClick={handlePricingClick}
              >
                <ListItemButton
                  style={{
                    background: "transparent",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 1rem"
                  }}
                  disableRipple // Disables the ripple effect
                  disableTouchRipple // Disables the ripple effect on touch devices
                >
                  <ListItemIcon style={{ padding: "0rem 0rem" }}>
                    <svg fill={selectedButton === 'pricing' ? onBoarding.color : 'black'} height="19px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_7175255"><path clip-rule="evenodd" d="m12 23c6.0751 0 11-4.9249 11-11 0-6.07513-4.9249-11-11-11-6.07513 0-11 4.92487-11 11 0 6.0751 4.92487 11 11 11zm1-17c0-.55228-.4477-1-1-1s-1 .44772-1 1v.58552c-1.41124.32645-2.5 1.5527-2.5 3.07527 0 1.51541 1.0949 2.78091 2.5643 3.08951l1.3923.2924c.6369.1338 1.0434.6589 1.0434 1.2134 0 .6489-.5599 1.2439-1.3374 1.2439h-.1626c-.7261 0-1.2985-.4741-1.4554-1.059-.143-.5334-.69136-.8499-1.2248-.7069s-.84995.6914-.70693 1.2249c.3123 1.1648 1.23154 2.0654 2.38713 2.3998v.6412c0 .5523.4477 1 1 1s1-.4477 1-1v-.6038c1.4169-.3578 2.5-1.6022 2.5-3.1401 0-1.5545-1.1232-2.8538-2.6324-3.1707l-1.3923-.2924c-.5972-.1254-.9753-.6168-.9753-1.13221 0-.603.5211-1.16079 1.2508-1.16079h.2492c.7261 0 1.2985.47408 1.4554 1.05896.143.53344.6914.84994 1.2248.70694s.8499-.69142.7069-1.22486c-.3123-1.16484-1.2315-2.0654-2.3871-2.39984z" fill="rgb(0,0,0)" fill-rule="evenodd"></path></svg>
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontWeight: selectedButton === 'Pricing' ? "700" : "500",
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                      color: selectedButton === 'pricing' ? onBoarding.color : 'black',
                      marginLeft: "-1.8rem"
                    }}
                  >
                    Pricing
                  </Typography>
                </ListItemButton>
              </ListItem>
              <ListItem style={{
                width: "100%", background:
                  selectedButton === 'setting'
                    ? convertToRgba(onBoarding.color, 0.10)
                    : "transparent",
                borderRight: selectedButton === 'setting' ? `6px solid ${onBoarding.color}` : "",
                // height:"50px",
                padding: "0.45rem 0.5rem"
              }}
              >
                <a href="https://billing.stripe.com/p/login/14k7vweFEeiNf9meUU" target="_blank" style={{ width: '100%' }} >
                  <ListItemButton
                    style={{
                      background: "transparent",
                      color: "black",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      padding: "0rem 1rem"
                    }}
                    disableRipple // Disables the ripple effect
                    disableTouchRipple // Disables the ripple effect on touch devices
                  >
                    <ListItemIcon style={{ padding: "0rem 0rem" }}>
                      <svg fill={selectedButton === 'setting' ? onBoarding.color : 'black'} height="19px" xmlns="http://www.w3.org/2000/svg" cursor="pointer" viewBox="0 0 24 24" id="fi_14515116"><g id="Icon"><path d="M18,2.25H6A1.76,1.76,0,0,0,4.25,4V20A1.76,1.76,0,0,0,6,21.75H18A1.76,1.76,0,0,0,19.75,20V4A1.76,1.76,0,0,0,18,2.25Zm-6.75,4V6a.75.75,0,0,1,1.5,0v.2a2.91,2.91,0,0,1,.84.34.75.75,0,1,1-.77,1.28A1.5,1.5,0,0,0,12,7.61H11.8a.26.26,0,0,0-.26.25.25.25,0,0,0,.17.24l1.08.39A1.75,1.75,0,0,1,14,10.14a1.77,1.77,0,0,1-1.21,1.66V12a.75.75,0,0,1-1.5,0v-.2a2.91,2.91,0,0,1-.84-.34.75.75,0,1,1,.77-1.28,1.5,1.5,0,0,0,.78.21h.24a.26.26,0,0,0,.26-.25.25.25,0,0,0-.17-.24l-1.08-.39A1.75,1.75,0,0,1,10,7.86,1.77,1.77,0,0,1,11.25,6.2ZM16,18.75H8a.75.75,0,0,1,0-1.5h8a.75.75,0,0,1,0,1.5Zm0-3H8a.75.75,0,0,1,0-1.5h8a.75.75,0,0,1,0,1.5Z"></path></g></svg>
                    </ListItemIcon>
                    <Typography
                      style={{
                        fontWeight: selectedButton === 'setting' ? "500" : "500",
                        fontSize: "14px",
                        fontFamily: "Poppins, sans-serif",
                        color: selectedButton === 'setting' ? onBoarding.color : 'black',
                        marginLeft: "-1.8rem"
                      }}
                    >
                      Billing
                    </Typography>
                  </ListItemButton>
                </a>
              </ListItem>
              <ListItem style={{
                width: "100%",
                padding: "0.45rem 0.5rem"
              }}
                onClick={() => { navigateTo('/contact-us') }}
              >
                <ListItemButton
                  style={{
                    background: "transparent",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 1rem"
                  }}
                  disableRipple
                  disableTouchRipple
                >
                  <ListItemIcon style={{ padding: "0rem 0rem" }}>
                    {/* <svg fill='black' height="17px" cursor="pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
                    </svg> */}
                    <svg fill='black' height="17px" cursor="pointer" id="fi_10994601" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.75 6v6c0 1.517-1.233 2.75-2.75 2.75h-8.324c-.242 0-.477.069-.679.201l-1.816 1.177c-.207.135-.443.202-.681.202-.205 0-.41-.051-.598-.152-.402-.219-.652-.64-.652-1.098v-9.08c0-1.517 1.233-2.75 2.75-2.75h10c1.517 0 2.75 1.233 2.75 2.75zm1.5 1.26v4.74c0 2.34-1.91 4.25-4.25 4.25h-8.25l-.44.29c.25 1.26 1.36 2.21 2.69 2.21h7.73c.63 0 1.24.18 1.77.52l1.32.86c.21.13.44.2.68.2.21 0 .41-.05.6-.15.4-.22.65-.64.65-1.1v-9.08c0-1.44-1.1-2.61-2.5-2.74z" />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                      color: 'black',
                      marginLeft: "-1.8rem"
                    }}
                  >
                    Support
                  </Typography>
                </ListItemButton>

              </ListItem>
              <ListItem style={{
                width: "100%",
                padding: "0.45rem 0.5rem"
              }}
                onClick={() => handleLogout()}
              >
                <ListItemButton
                  style={{
                    background: "transparent",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "0rem 1rem"
                  }}
                  disableRipple // Disables the ripple effect
                  disableTouchRipple // Disables the ripple effect on touch devices
                >
                  <ListItemIcon style={{ padding: "0rem 0rem" }}>
                    {/* <svg fill='black' height="17px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path fill="#000000" d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                    </svg> */}
                    <svg
                      id="fi_3596129"
                      fill='black'
                      height="17px"
                      enableBackground="new 0 0 24 24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M11 15c-1.654 0-3-1.346-3-3s1.346-3 3-3h5V2.75C16 1.233 14.767 0 13.25 0h-10.5C1.233 0 0 1.233 0 2.75v18.5C0 22.767 1.233 24 2.75 24h10.5C14.767 24 16 22.767 16 21.25V15h-5z" />
                      <path d="M18.617 16.924c-.373-.155-.617-.52-.617-.924v-3h-7c-.552 0-1-.448-1-1s.448-1 1-1h7v-3c0-.404.244-.769.617-.924.374-.155.804-.069 1.09.217l4 4c.391.391.391 1.023 0 1.414l-4 4c-.286.286-.716.372-1.09.217z" />
                    </svg>
                  </ListItemIcon>
                  <Typography
                    style={{
                      // fontWeight: selectedButton === 'setting' ? "500" : "500",
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                      color: 'black',
                      marginLeft: "-1.8rem"
                    }}
                  >
                    Logout
                  </Typography>
                </ListItemButton>

              </ListItem>
            </List>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }} className="element">
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ background: "transparent", color: "black", height: "0px" }}
      >
        {/* <Divider /> */}
        <Toolbar sx={{ mr: 2, display: { sm: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        style={{
          // boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.07)", // Adding box shadow to the right
          borderRight: '0px solid transparent',
          border: 'none',
          minHeight: "100vh",
          zIndex: '5',
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar  />
        <Dashboard />
      </Box> */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          background: '#F8F8F8',
          // pt: selectedButton === 'Business Chatbot' ? 0 : 5,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {/* <Toolbar />
      <Dashboard />
      <MenuFooter /> */}
        {/* {
        props.component
      } */}

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "100vh",
              alignItems: "center",
            }}
          >
            <div id="loading-bar-spinner" class="spinner">
              <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
            </div>
          </div>
        ) : (
          props.component
        )}
      </Box>
      {/* Include your MenuFooter component here */}
    </Box>

  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
