import React, { useState, useEffect, useRef } from 'react'
import Classes from './ScreeningDashboard.module.css'
import Color from 'color';
import RowComp from './RowComp';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate, useNavigate } from 'react-router-dom';
import backend from '../../api';
import { useSelector } from 'react-redux';
import { setCompanyInfo } from '../../redux-store/auth/actions';
import { useDispatch } from 'react-redux';
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import sessionstorage from "sessionstorage";
import { CircularProgress } from '@mui/material';
import EmailSuccessPopup from '../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';
import EmailEditorPopup from '../../Global-Components/EmailEditorPopup/EmailEditorPopup';
import DemoRowComp from './DemoRowComp';
import MobileVersionPopup from '../../Global-Components/MobileVersionPopup/MobileVersionPopup';
import OnBoardingSuccessPopup from '../../Global-Components/OnBoardingSuccessPopup/OnBoardingSuccessPopup';
import EmailMassEditorPopup from '../../Global-Components/EmailMassEditorPopup/EmailMassEditorPopup';
import FilterDropDown from './Comp/FilterDropDown';
import Notifications from '../../On-boarding/Global-Components/Notification/Notifcation';

import ContentLoader from 'react-content-loader';

const RowSkeleton = () => (
    <div style={{ height: '3.1rem', padding: '9px 20px', display: 'flex', alignItems: 'center' }} >
        <ContentLoader
            speed={1.2}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            height="32"
        >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="32" />
        </ContentLoader>
    </div>
);
const SmallRowSkeleton = (props) => (
    <div style={{ height: '1.7em', width: props.width, display: 'flex', alignItems: 'center' }} >
        <ContentLoader
            speed={1.2}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="0" rx="4" ry="4" width="97.5%" {...props} />
        </ContentLoader>
    </div>
);

export default function ScreeningDashboard() {
    const navigateTo = useNavigate();
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const { user } = useSelector((state) => state.auth);
    const [deckData, setDeckData] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [last30DaysDeck, setLast30DaysDeck] = useState(0);
    const [avgDeckScore, setAverageDeckScore] = useState(0);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('All');
    const [selectedButton, setSelectedButton] = useState('');
    const [Industry, setIndustry] = useState('SaaS');
    const [recentComments, setRecentComments] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});


    const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);

    const userID = user?._id
    const dispatch = useDispatch();

    const [openEmailConnectedPopup, setopenEmailConnectedPopup] = useState(false);


    const [searchTerm, setSearchTerm] = useState("");

    const [filteredDecks, setFilteredDecks] = useState([]); // Data to display



    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = deckData?.filter((deck) => {
            // Safely check if companyName exists and convert it to lowercase
            const companyName = deck?.userId?.companyName?.toLowerCase() || "";


            // Filter based on companyName that starts with the search term
            return companyName.startsWith(value);
        });

        console.log(filtered);

        setFilteredDecks(filtered);
    };


    useEffect(() => {
        if (selectedButton === 'Setting') {
            sessionstorage.setItem("navBtn", "Setting")
            navigateTo('/setting');
        }
    }, [selectedButton])
    const [decisionLoading, setdecisionLoading] = useState(false);

    const handleCloseEmailConnectedPopup = () => {
        setopenEmailConnectedPopup(false);
    }

    const [openEmailSentPopup, setopenEmailSentPopup] = useState(false);
    const handleCloseEmailSentPopup = () => {
        setopenEmailSentPopup(false);
    }

    const handleEmailSentPopupOpen = () => {
        setopenEmailSentPopup(true);
    }

    const [openEmailEditorPopup, setopenEmailEditorPopup] = useState(false);
    const handleCloseEmailEditorPopup = () => {
        setopenEmailEditorPopup(false);
    }

    const handleEmailEditerOpen = () => {
        setopenEmailEditorPopup(true);
    }

    const [openNotifications, setopenNotifications] = useState(false);
    const NotificationsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
                setopenNotifications(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleNotifications = (event) => {
        event.stopPropagation(); // Prevents event propagation to document
        setopenNotifications(prevState => !prevState);
    };
    const handleSettingClick = () => {
        setSelectedButton('Setting');
    }

    const convertToRgba = (colorInput, opacity) => {
        try {
            const color = Color(colorInput);
            return color.alpha(opacity).rgb().string();
        } catch (error) {
            console.error("Invalid color format:", colorInput);
            return `rgba(0, 0, 0, ${opacity})`;
        }
    };

    const [copied, setCopied] = useState(false);
    const handleCopyText = () => {
        const textToCopy = `${onBoarding.companyName}.evala.ai`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };



    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calculate total pages
    const totalPages = Math.ceil(filteredDecks?.length / itemsPerPage);

    // Get current items
    const currentItems = filteredDecks?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // Calculate the range of items currently being shown
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, filteredDecks?.length);
    const totalItems = filteredDecks?.length;

    const companyId = onBoarding?._id;

    const handleFilterChange = (newFilter) => {
        // If the new filter is the same as the current filter, set it to "All"
        if (filter === newFilter) {
            setFilter('All');
        } else {
            // Otherwise, update to the new filter
            setFilter(newFilter);
        }
    };

    useEffect(() => {
        const combinedComments = deckData.flatMap(deck => {
            const feedbackComments = deck.feedback.map(feedback => ({
                user: feedback.userId, // Assumes feedback.userId is populated with fullname
                comment: feedback.comment,
                createdAt: feedback.createdAt,
                isAdmin: false,
                companyName: deck.companyId.companyName, // Assumes companyId is populated with companyName
            }));
            const adminComments = deck.AdminComments.map(adminComment => ({
                user: adminComment.userId, // Assumes adminComment.userId is populated with fullname
                comment: adminComment.comment,
                createdAt: adminComment.createdAt,
                isAdmin: true,
                companyName: deck.companyId.companyName,
            }));
            return [...feedbackComments, ...adminComments];
        });

        // Sort combined comments by createdAt descending
        const sortedComments = combinedComments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Take top five recent comments
        const recentFiveComments = sortedComments.slice(0, 5);

        setRecentComments(recentFiveComments);
    }, [deckData]);



    useEffect(() => {
        const fetchData = async () => {
            try {
                // Check if companyName exists in database
                const response = await backend.get(`/deck/bycompany?companyId=${companyId}`);

                // Filter the response data where adminDelete is false
                const filteredData = response?.data?.filter(item => item?.adminDelete === false && item?.adminDeck === false && item?.status === "submitted");
                const industries = filteredData?.map(item => item?.userId?.CustomerData?.industry.map(industry => industry)) || ['SaaS'];
                console.log(industries)

                console.log("All Industries", industries)

                const chosenName = getMostFrequentOrRandomCompanyName(industries);
                setIndustry(chosenName)



                // Calculate the date 30 days ago
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(thirtyDaysAgo?.getDate() - 30);

                // Filter the data for the last 30 days
                const last30DaysData = filteredData?.filter(item => new Date(item?.createdAt) >= thirtyDaysAgo);

                // Log the filtered data
                console.log(filteredData);
                // Log or set the length of the last 30 days data
                console.log(last30DaysData?.length);
                setLast30DaysDeck(last30DaysData?.length);

                // Calculate total score for each deck
                const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
                const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

                const calculateTotalScore = (deck) => {
                    const scores = {
                        deckStructure: parseFloat(deck?.deckStructureScoreMatch) || 0,
                        marketOpportunity: parseFloat(deck?.marketPotentialScoreMatch) || 0,
                        marketingSales: parseFloat(deck?.marketingSalesScoreMatch) || 0,
                        businessModel: parseFloat(deck?.businessModelScoreMatch) || 0,
                        competitiveLandscape: parseFloat(deck?.competitiveLandscapeScoreMatch) || 0,
                        team: parseFloat(deck?.teamScoreMatch) || 0,
                        traction: parseFloat(deck?.tractionScoreMatch) || 0
                    };

                    let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
                        return acc + (scores[criteria.name] * weight) ?? 0;
                    }, 0);

                    return +(totalScore?.toFixed(2)); // Round to two decimal places
                };

                // Add totalScore to each deck and sort by totalScore in descending order
                const decksWithScores = filteredData?.map(deck => ({
                    ...deck,
                    totalScore: calculateTotalScore(deck)
                })).sort((a, b) => b.totalScore - a.totalScore);



                // const getFilteredDecks = () => {
                //     const topCounts = {
                //         'Top 10': 10,
                //         'Top 25': 25,
                //         'Top 100': 100
                //     };

                //     if (filter === 'All') {
                //         return decksWithScores;
                //     }

                //     const count = topCounts[filter]; // Default to Top 10 if no filter is selected


                //     return decksWithScores.slice(0, count);
                // };

                // const filteredDecks = getFilteredDecks();

                // Update the state with the sorted array
                setDeckData(decksWithScores);
                setFilteredDecks(decksWithScores);


                // Calculate the average score of all decks
                const totalScoresSum = decksWithScores?.reduce((acc, deck) => acc + deck.totalScore, 0) ?? 0;
                const averageScore = totalScoresSum / decksWithScores.length;

                // Log or use the average score
                console.log('Average Score:', averageScore);
                setAverageDeckScore(averageScore);

                // Use filtered data as needed
                // ...
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        const fetchUsers = async () => {
            try {
                // Check if companyName exists in database
                const response = await backend.get(`/api/user/bycompany?companyId=${companyId}`);
                console.log(response.data)
                if (response?.data) {
                    setTotalUser(response?.data?.length)
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        fetchUsers();

    }, [companyId, onBoarding, filter]); // Dependency on companyId ensures useEffect runs when companyId changes


    const getMostFrequentOrRandomCompanyName = (industries) => {


        if (industries.length === 1) {
            return industries[0]; // Only one item, return it
        }

        // Count occurrences of each company name
        const nameCounts = industries?.reduce((acc, name) => {
            acc[name] = (acc[name] || 0) + 1;
            return acc ?? 0;
        }, {});

        // Find the maximum occurrence count
        const maxCount = Math.max(...Object.values(nameCounts));

        // Get all names with the maximum count
        const mostFrequentNames = Object.keys(nameCounts).filter(name => nameCounts[name] === maxCount);

        if (mostFrequentNames.length === 1) {
            return mostFrequentNames[0]; // Return the most frequent name
        } else {
            // If there's a tie, pick one randomly
            const randomIndex = Math.floor(Math.random() * mostFrequentNames.length);
            return mostFrequentNames[randomIndex];
        }
    };

    // Example usage
    // const companyNames = ["CompanyA", "CompanyB", "CompanyA", "CompanyC", "CompanyB", "CompanyA"];
    // console.log(chosenName);

    useEffect(() => {
        const fetchData = async () => {
            try {

                // Check if companyName exists in database
                const response = await backend.get(`/onboarding/?userID=${userID}`);
                // console.log(response.data[0])
                // If response.data is not empty, companyName exists in database
                if (response.data.length > 0) {
                    dispatch(setCompanyInfo(response.data[0]))
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures useEffect runs once on component mount

    // Function to handle update and filter data
    const handleDelete = async (deckId) => {
        try {
            setLoading(true)
            // Update backend to mark adminDelete as true for the deckId
            await backend.put(`/deck/${deckId}`, { adminDelete: true });

            // Refetch and filter data
            RefetchData();
            setLoading(false)


        } catch (error) {
            console.error('Error updating or fetching data:', error);
        }
    };

    const RefetchData = async () => {

        try {
            // Check if companyName exists in database
            const response = await backend.get(`/deck/bycompany?companyId=${companyId}`);

            // Filter the response data where adminDelete is false
            const filteredData = response?.data?.filter(item => item?.adminDelete === false && item?.adminDeck === false && item?.status === "submitted");
            const industries = filteredData?.map(item => item?.userId?.CustomerData?.industry.map(industry => industry)) || ['SaaS'];

            console.log(industries)
            const chosenName = getMostFrequentOrRandomCompanyName(industries);
            setIndustry(chosenName)

            // Calculate the date 30 days ago
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo?.getDate() - 30);

            // Filter the data for the last 30 days
            const last30DaysData = filteredData?.filter(item => new Date(item?.createdAt) >= thirtyDaysAgo);

            // Log the filtered data
            console.log(filteredData);
            setDeckData(filteredData);
            setFilteredDecks(filteredData);


            // Log or set the length of the last 30 days data
            console.log(last30DaysData?.length);
            setLast30DaysDeck(last30DaysData?.length);

            // Calculate total score for each deck
            const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
            const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

            const calculateTotalScore = (deck) => {
                const scores = {
                    deckStructure: parseFloat(deck?.deckStructureScoreMatch) || 0,
                    marketOpportunity: parseFloat(deck?.marketPotentialScoreMatch) || 0,
                    marketingSales: parseFloat(deck?.marketingSalesScoreMatch) || 0,
                    businessModel: parseFloat(deck?.businessModelScoreMatch) || 0,
                    competitiveLandscape: parseFloat(deck?.competitiveLandscapeScoreMatch) || 0,
                    team: parseFloat(deck?.teamScoreMatch) || 0,
                    traction: parseFloat(deck?.tractionScoreMatch) || 0
                };

                let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
                    return acc + (scores[criteria.name] * weight) ?? 0;
                }, 0);

                return +(totalScore?.toFixed(2)); // Round to two decimal places
            };

            // Add totalScore to each deck and sort by totalScore in descending order
            const decksWithScores = filteredData.map(deck => ({
                ...deck,
                totalScore: calculateTotalScore(deck)
            })).sort((a, b) => b.totalScore - a.totalScore);

            // Update the state with the sorted array
            setDeckData(decksWithScores);
            setFilteredDecks(decksWithScores);


            // Calculate the average score of all decks
            const totalScoresSum = decksWithScores?.reduce((acc, deck) => acc + deck.totalScore, 0) ?? 0;
            const averageScore = totalScoresSum / decksWithScores.length;

            // Log or use the average score
            console.log('Average Score:', averageScore);
            setAverageDeckScore(averageScore);

            // Use filtered data as needed
            // ...

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [demoDecks, setdemoDecks] = useState([
        {
            score: 80,
            companyName: 'Stripe',
            industry: 'FinTech',
            description: `Stripe is a global technology company that builds economic infrastructure for the internet. Its core service enables businesses of all sizes- from startups to large enterprises-to accept online payments securely and efficiently. Stripe provides a suite of APIs that allow developers to integrate payment processing into websites and mobile apps easily. In addition to payments, Stripe offers solutions for managing subscriptions, fraud prevention, financial reporting, and more.`,
            founders: 'Patrick Collison and John Collision',
            website: 'www.stripe.com',
            email: 'contact@stripe.com',
            link: '/demo',
            businessModelScoreMatch: '85',
            businessModelParagraphMatch: `Stripe's business model is robust, focusing on continuous innovation and developer-first tools, which are critical in the rapidly evolving tech landscape. The deck successfully communicates how Stripe's comprehensive toolkit and partnerships facilitate business operations globally, fostering a strong value proposition.`,
            deckStructureScoreMatch: '80',
            deckStructureParagraphMatch: `The overall structure of the pitch deck is well-organized, flowing logically from introducing Stripe's capabilities to detailing its global impact and customer base, which helps in maintaining audience engagement throughout the presentation.`,
            marketPotentialScoreMatch: '75',
            marketPotentialParagraphMatch: `Stripe operates in a high-growth market with increasing demands for online payment solutions. The presentation effectively highlights the vast market potential and Stripe's ability to capitalize on this through its global reach and scalable platform.`,
            marketingSalesScoreMatch: '78',
            marketingSalesParagraphMatch: `The pitch effectively outlines Stripe's marketing and sales strategies, showcasing their direct alignment with business growth and customer acquisition. Demonstrations of strategic partnerships and client testimonials could further enhance this section.`,
            competitiveLandscapeScoreMatch: '74',
            competitiveLandscapeParagraphMatch: `The competitive analysis outlines key differentiators that set Stripe apart in the market. However, more detailed comparisons with direct competitors could provide a clearer picture of Stripe's market standing.`,
            teamScoreMatch: '82',
            teamParagraphMatch: `The leadership team is presented as experienced and well-equipped to drive the company's vision. Including more specific achievements of team members could further strengthen this section.`,
            tractionScoreMatch: '85',
            tractionParagraphMatch: `The deck presents impressive traction, with a broad user base and significant transaction volumes. More detailed statistics on user growth rates and market penetration would provide deeper insights into Stripe's success.`,
        },
    ])

    console.log(activeScoringCriteria)
    // Function to filter and flatten objects based on active scoring criteria
    const filterAndFlattenObject = (obj) => {
        const activeCriteriaNames = activeScoringCriteria.map(criteria => criteria.name);
        const weight = activeScoringCriteria.length ? 1 / activeScoringCriteria.length : 0;



        // Parse scores and calculate the total score dynamically
        const scores = {
            deckStructure: parseFloat(obj?.deckStructureScoreMatch) || 0,
            marketOpportunity: parseFloat(obj?.marketPotentialScoreMatch) || 0,
            marketingSales: parseFloat(obj?.marketingSalesScoreMatch) || 0,
            businessModel: parseFloat(obj?.businessModelScoreMatch) || 0,
            competitiveLandscape: parseFloat(obj?.competitiveLandscapeScoreMatch) || 0,
            team: parseFloat(obj?.teamScoreMatch) || 0,
            traction: parseFloat(obj?.tractionScoreMatch) || 0
        };

        // Calculate totalScore
        let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
            return acc + (scores[criteria.name] * weight) ?? 0;
        }, 0);
        totalScore = +(totalScore?.toFixed(2)); // Round to two decimal places

        const filteredObject = {
            user_id: obj.userId?._id,
            user_email: obj.userId?.email,
            user_founders: obj.userId?.founders,
            user_website: obj.userId?.website,
            user_companyName: obj.userId?.companyName,
            user_industry: obj.userId?.industry,
            user_companyDescription: obj.userId?.companyDescription,
            total_score: totalScore,

        };

        // Map the criteria to corresponding scores and paragraphs
        if (activeCriteriaNames.includes('businessModel')) {
            filteredObject.businessModelScoreMatch = obj.businessModelScoreMatch;
            filteredObject.businessModelParagraphMatch = obj.businessModelParagraphMatch;
        }
        if (activeCriteriaNames.includes('deckStructure')) {
            filteredObject.deckStructureScoreMatch = obj.deckStructureScoreMatch;
            filteredObject.deckStructureParagraphMatch = obj.deckStructureParagraphMatch;
        }
        if (activeCriteriaNames.includes('marketOpportunity')) {
            filteredObject.marketPotentialScoreMatch = obj.marketPotentialScoreMatch;
            filteredObject.marketPotentialParagraphMatch = obj.marketPotentialParagraphMatch;
        }
        if (activeCriteriaNames.includes('marketingSales')) {
            filteredObject.marketingSalesScoreMatch = obj.marketingSalesScoreMatch;
            filteredObject.marketingSalesParagraphMatch = obj.marketingSalesParagraphMatch;
        }
        if (activeCriteriaNames.includes('competitiveLandscape')) {
            filteredObject.competitiveLandscapeScoreMatch = obj.competitiveLandscapeScoreMatch;
            filteredObject.competitiveLandscapeParagraphMatch = obj.competitiveLandscapeParagraphMatch;
        }
        if (activeCriteriaNames.includes('team')) {
            filteredObject.teamScoreMatch = obj.teamScoreMatch;
            filteredObject.teamParagraphMatch = obj.teamParagraphMatch;
        }
        if (activeCriteriaNames.includes('traction')) {
            filteredObject.tractionScoreMatch = obj.tractionScoreMatch;
            filteredObject.tractionParagraphMatch = obj.tractionParagraphMatch;
        }

        return filteredObject;
    };

    // Function to convert data to CSV format
    const convertToCSV = (data) => {
        const filteredData = data.map(item => filterAndFlattenObject(item));
        const headers = Object.keys(filteredData[0]).join(','); // Extract headers
        const rows = filteredData.map(obj =>
            Object.values(obj).map(val => `"${val || ''}"`).join(',')
        );
        return [headers, ...rows].join('\n');
    };

    // Function to download the CSV file
    const downloadCSV = (data, filename = `${onBoarding?.companyName}-decks.csv`) => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [openMassEmailSentPopup, setopenMassEmailSentPopup] = useState(false);
    const handleCloseMassEmailSentPopup = () => {
        setopenMassEmailSentPopup(false);
    }
    const handleOpenMassEmailSent = () => {
        setopenMassEmailSentPopup(true);
    }

    useEffect(() => {
        console.log(openNotifications)
    }, [openNotifications])

    // (loading && !onBoarding?.isFirstInteraction) ?
    // <div style={{ background: 'white' }} >
    //     <div className={Classes.banner} >

    //     </div>
    //     <div className={Classes.mainRightBg} >
    //         <div
    //             style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 height: "100vh",
    //                 alignItems: "center",
    //             }}
    //         >
    //             <div id="loading-bar-spinner" class="spinner">
    //                 <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    // :

    const decisionMapping = {
        Approved: 'approve',
        Rejected: 'reject',
        Undecided: 'select', // If you add an 'Undecided' option
      };

      
    useEffect(() => {
        let filtered = deckData;
      
        // Apply search filter
        if (searchTerm) {
          const lowerSearchTerm = searchTerm.toLowerCase();
          filtered = filtered?.filter((deck) => {
            const companyName = deck?.userId?.companyName?.toLowerCase() || '';
            return companyName?.startsWith(lowerSearchTerm);
          });
        }
      
        // Apply selected filters
        if (selectedFilters && Object.keys(selectedFilters).length > 0) {
          filtered = filtered.filter((deck) => {
            let matchesAllFilters = true;
            for (const filterTitle in selectedFilters) {
              const selectedOptions = selectedFilters[filterTitle];
              if (selectedOptions?.length === 0) continue;
      
              if (filterTitle === 'Decision') {
                // Map selected options to data values
                const selectedDataValues = selectedOptions.map(
                  (option) => decisionMapping[option]
                );
      
                if (!selectedDataValues.includes(deck.decision)) {
                  matchesAllFilters = false;
                  break;
                }
              } else if (filterTitle === 'Industry') {
                const deckIndustry = deck?.userId?.CustomerData?.industry || [];
                const matchesIndustry = deckIndustry.some((ind) =>
                  selectedOptions.includes(ind)
                );
                if (!matchesIndustry) {
                  matchesAllFilters = false;
                  break;
                }
              }
            }
            return matchesAllFilters;
          });
        }
      
        setFilteredDecks(filtered);
      }, [searchTerm, deckData, selectedFilters]);
      

console.log(selectedFilters)

    return (
        <>
            <AnimatePresence>
                {openEmailConnectedPopup && <EmailSuccessPopup closePopup={handleCloseEmailConnectedPopup}
                    title="WOOHOO!"
                    description="Your email has been connected successfully."
                    buttonText="Return to dashboard"
                />}
            </AnimatePresence>
            <AnimatePresence>
                {openEmailEditorPopup && <EmailMassEditorPopup closePopup={handleCloseEmailEditorPopup} handleOpenMassEmailSent={handleOpenMassEmailSent} />}
            </AnimatePresence>
            <AnimatePresence>
                {openMassEmailSentPopup && <EmailSuccessPopup closePopup={handleCloseMassEmailSentPopup}
                    title="WOOHOO!"
                    description="Your email has been sent successfully."
                    buttonText="Continue screening"
                />}
            </AnimatePresence>
            <AnimatePresence>
                {openEmailSentPopup && <EmailSuccessPopup closePopup={handleCloseEmailSentPopup}
                    title="WOOHOO!"
                    description="Your email has been sent successfully."
                    buttonText="Continue screening"
                />}
            </AnimatePresence>
            <MobileVersionPopup />
            <div style={{ background: 'white' }} >
                <div className={Classes.banner} >
                    <div className={Classes.flexCenter} >
                        <h5 className={Classes.fw600} >Hello, {onBoarding.companyName}!</h5>
                    </div>
                    <div className={Classes.flexAlign} >
                        <div className={Classes.bannerMid} >
                            <div className={Classes.bmLeft} >
                                <h5>{onBoarding.companyName}</h5>
                            </div>
                            <div className={Classes.bmRight} style={{ background: convertToRgba(onBoarding.color, 0.10) }} >
                                <h5>.evala.ai</h5>
                            </div>
                        </div>
                        <div>
                            {copied ?
                                <svg xmlns="http://www.w3.org/2000/svg" height="17px" width="17px" viewBox="0 0 448 512"><path fill="#000000" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                :
                                <svg onClick={handleCopyText} xmlns="http://www.w3.org/2000/svg" height="17px" width="17px" cursor="pointer" viewBox="0 0 448 512"><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" /></svg>
                            }
                        </div>
                    </div>
                    <div className={Classes.bannerRight} >
                        {/* <h5 onClick={handleSettingClick} style={{ cursor: 'pointer' }} className={Classes.fw600} >Settings</h5> */}
                        {/* <svg onClick={handleSettingClick} xmlns="http://www.w3.org/2000/svg" height="17px" cursor="pointer" viewBox="0 0 512 512">
                                <path fill="#000000" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
                            </svg> */}
                        <Notifications />

                    </div>
                </div>
                <div className={Classes.mainRightBg} >
                    <div className={Classes.hero1} >
                        <div className={Classes.heroBox} >
                            <div className={Classes.hero1Img} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <img src='/onBoarding/companies.png' />
                            </div>
                            <div>
                                <h5>Companies</h5>
                                {/* <h3>{currentItems?.length > 0 ? totalUser : 1}</h3> */}
                                <h3>
                                    {loading ? <SmallRowSkeleton height={'1.2rem'} width={'4rem'} /> :
                                        deckData?.length ? deckData?.length : 1
                                    }
                                </h3>
                            </div>
                        </div>
                        <div className={Classes.miniLine} ></div>
                        <div className={Classes.heroBox} >
                            <div className={Classes.hero1Img} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <img src='/onBoarding/score.png' />
                            </div>
                            <div>
                                <h5>Average Score</h5>
                                <h3>
                                    {loading ? <SmallRowSkeleton height={'1.2rem'} width={'4rem'} /> :
                                        isNaN(avgDeckScore) ? 80 : avgDeckScore.toFixed(2)}
                                </h3>
                            </div>
                        </div>
                        <div className={Classes.miniLine} ></div>
                        <div className={Classes.heroBox} >
                            <div className={Classes.hero1Img} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <img src='/onBoarding/industry.png' />
                            </div>
                            <div>
                                <h5>Main Industry</h5>
                                <h3>
                                    {loading ? <SmallRowSkeleton height={'1.2rem'} width={'5rem'} /> :
                                        Industry ? Industry : "SaaS"}
                                </h3>
                            </div>
                        </div>
                        <div className={Classes.miniLine} ></div>
                        <div className={Classes.heroBox} >
                            <div className={Classes.hero1Img} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <img src='/onBoarding/deck.png' />
                            </div>
                            <div>
                                <h5>Decks Last 30d</h5>
                                <h3>
                                    {loading ? <SmallRowSkeleton height={'1.2rem'} width={'4rem'} /> :
                                        last30DaysDeck}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className={Classes.hero2} >
                        <div className={Classes.searcCon} >
                            <img src="/icons/search.png" alt="icon" />
                            <input type="text" placeholder="Search by company"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className={Classes.flexAlign10} >
                            <div className={`${Classes.flexAlign10} pointer ${Classes.mr10}`} onClick={() => { setopenEmailEditorPopup(true) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                                <h5>Mass Email</h5>
                            </div>
                            <div className={`${Classes.flexAlign10} pointer ${Classes.mr10}`} onClick={() => { currentItems && currentItems.length > 0 ? downloadCSV(currentItems) : downloadCSV(demoDecks) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                                <h5>Export</h5>
                            </div>
                            <FilterDropDown onFilterChange={setSelectedFilters} />
                            </div>
                    </div>
                    <div className={Classes.hero3} >
                        <div className={Classes.tableCon} >
                            <div className={Classes.tableHeader} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <div> <h5>Rank</h5> </div>
                                <div> <h5>Score</h5> </div>
                                <div> <h5>Company name</h5> </div>
                                <div> <h5>Industry</h5> </div>
                                <div> <h5>Pitch deck</h5> </div>
                                <div> <h5>Decision</h5> </div>
                            </div>
                            <div className={Classes.tableBody} >
                                {currentItems && currentItems.length > 0 ? currentItems?.map((item, index) => (
                                    <RowComp key={item._id} value={item} index={(currentPage - 1) * itemsPerPage + index}
                                        handleDelete={() => handleDelete(item._id)}
                                        deckId={item._id}
                                        setdecisionLoading={setdecisionLoading}
                                    />
                                )) :
                                    loading ? Array.from({ length: 5 }).map((_, index) => (
                                        <RowSkeleton key={index} />
                                    )) :
                                        <>
                                            {demoDecks?.map((item, index) => (
                                                <DemoRowComp key={index + 1} value={item} index={index} />
                                            ))}
                                        </>
                                }
                                {decisionLoading &&
                                    <div className={Classes.loadingPopup} > <h5>Loading...</h5> </div>
                                }
                            </div>
                            <div className={Classes.pagFooter} >
                                <h5 style={{ height: '1.2rem' }} > {loading ? <SmallRowSkeleton height={'1.2rem'} width={'10rem'} /> : `Showing ${startItem} to ${endItem} of ${totalItems} results`} </h5>
                                {loading ? <SmallRowSkeleton height={'1.2rem'} width={'5rem'} /> :
                                    <Stack spacing={2} className={Classes.pagination}>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            shape="rounded"
                                        />
                                    </Stack>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
