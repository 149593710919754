import React, { useState, useEffect, useRef } from 'react'
import Classes from './Notification.module.css'
import Color from 'color';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate, useNavigate } from 'react-router-dom';
import backend from '../../../api';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { motion, easeOut, AnimatePresence } from 'framer-motion';

export default function Notifications() {
    const navigateTo = useNavigate();
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const { user } = useSelector((state) => state.auth);
    const [deckData, setDeckData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('All');
    const [recentComments, setRecentComments] = useState([]);
    const [openNotifications, setopenNotifications] = useState(false);
    const NotificationsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
                setopenNotifications(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleNotifications = (event) => {
        event.stopPropagation(); // Prevents event propagation to document
        setopenNotifications(prevState => !prevState);
    };
    const companyId = onBoarding?._id;

    useEffect(() => {
        const combinedComments = deckData.flatMap(deck => {
            const feedbackComments = deck.feedback.map(feedback => ({
                user: feedback.userId, // Assumes feedback.userId is populated with fullname
                comment: feedback.comment,
                score: feedback.score, // Include the score
                createdAt: feedback.createdAt,
                isAdmin: false,
                companyName: deck.userId.companyName, // Assumes companyId is populated with companyName
            }));
            const adminComments = deck.AdminComments.map(adminComment => ({
                user: adminComment.userId, // Assumes adminComment.userId is populated with fullname
                comment: adminComment.comment,
                score: null, // Admin comments may not have a score
                createdAt: adminComment.createdAt,
                isAdmin: true,
                companyName: deck.userId.companyName,
            }));
            return [...feedbackComments, ...adminComments];
        });

        // Sort combined comments by createdAt descending
        const sortedComments = combinedComments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Take top five recent comments
        const recentFiveComments = sortedComments.slice(0, 5);

        setRecentComments(recentFiveComments);
    }, [deckData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Check if companyName exists in database
                const response = await backend.get(`/deck/bycompany?companyId=${companyId}`);

                // Filter the response data where adminDelete is false
                const filteredData = response?.data?.filter(item => item?.adminDelete === false && item?.adminDeck === false && item?.status === "submitted");

                // Log the filtered data
                console.log(filteredData);
                setDeckData(filteredData);


                // Use filtered data as needed
                // ...
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();

    }, [companyId, onBoarding]);

    useEffect(() => {
        console.log(openNotifications)
    }, [openNotifications])

    return (
        <>
            <div className={Classes.mainNotifications}
                ref={NotificationsRef} >
                <div className={Classes.bellCon} onClick={toggleNotifications}>
                    <img src='/bell.png' alt='bell' draggable={false} />
                    <span> <h5>{recentComments?.length > 0 ? recentComments?.length : 0}</h5> </span>
                </div>
                <AnimatePresence>
                    {openNotifications && (
                        <motion.div
                            initial={{ opacity: 0, y: -5 }} // Animation when component enters
                            animate={{ opacity: 1, y: 0 }}   // Animation when component is open
                            exit={{ opacity: 0, y: -5 }}    // Animation when component exits
                            transition={{ duration: 0.2 }}
                            className={Classes.notifications}
                        >
                            <div className={Classes.notifyBanner}><h5>Notifications</h5></div>
                            <div className={Classes.notifyBody}>
                                {recentComments?.length === 0 ? (
                                    <div style={{ textAlign: 'center', padding: '20px' }}>
                                        <img className={Classes.emptyImg} src='/empty-box.png' alt='box' style={{ height: '5rem' }} />
                                        <h5 className={Classes.emptyText} >No new notifications</h5>
                                    </div>
                                ) : (
                                    recentComments?.map((item, index) => {
                                        // Construct the notification message
                                        let action = '';
                                        if (item?.isAdmin) {
                                            // For admin comments
                                            if (item?.comment) {
                                                action = `commented: "${item?.comment}"`;
                                            }
                                        } else {
                                            // For feedback comments
                                            if (item?.score != null && item?.comment) {
                                                const truncatedComment = item.comment.length > 60 ? item.comment.substring(0, 60) + '...' : item.comment;
                                                action = `gave a score of ${item.score} and commented: "${truncatedComment}"`;
                                            } else if (item?.score != null) {
                                                action = `gave a score of ${item?.score}`;
                                            } else if (item.comment) {
                                                action = `commented: "${item?.comment}"`;
                                            }
                                        }

                                        return (
                                            <div key={index} >
                                                <h5 className={Classes.NotifyDesc}>
                                                    <strong>{item?.user?.role === "company" ? "Admin" : item?.user?.fullname}</strong> has {action} at company <strong>{item?.companyName}</strong>
                                                </h5>
                                                <h5 className={Classes.date}>
                                                    {new Date(item?.createdAt).toLocaleString()}
                                                </h5>
                                                {index !== recentComments.length - 1 &&
                                                    <div className={Classes.line2} ></div>
                                                }
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </>
    )
}
