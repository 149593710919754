import React, { useEffect, useRef, useState } from 'react'
import Classes from './Team.module.css'
import { useSelector } from 'react-redux';

import EmailSuccessPopup from '../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';
import { CircularProgress } from '@mui/material';
import { setDeckId } from '../../redux-store/auth/actions';
import { useDispatch } from 'react-redux';
import sessionstorage from 'sessionstorage';
import { useNavigate } from 'react-router-dom';
import backend from '../../api';
import { AnimatePresence, motion } from 'framer-motion';
import { updateDeckInfo } from '../../redux-store/auth/actions';


export default function DemoRowComp({ value, index, setdecisionLoading }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const {user} = useSelector((state) => state.auth);
    const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const userId = user?._id;

    const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

    const [openEmailSentPopup, setopenEmailSentPopup] = useState(false);
    const handleCloseEmailSentPopup = () => {
        setopenEmailSentPopup(false);
    }

    const [dropDown, setdropDown] = useState(false);
    const handleClosedropdown = () => {
        setdropDown(false);
    }

    // Parse scores and calculate the total score dynamically
    const scores = {
        deckStructure: parseFloat(value?.deckStructureScoreMatch) || 0,
        marketOpportunity: parseFloat(value?.marketPotentialScoreMatch) || 0,
        marketingSales: parseFloat(value?.marketingSalesScoreMatch) || 0,
        businessModel: parseFloat(value?.businessModelScoreMatch) || 0,
        competitiveLandscape: parseFloat(value?.competitiveLandscapeScoreMatch) || 0,
        team: parseFloat(value?.teamScoreMatch) || 0,
        traction: parseFloat(value?.tractionScoreMatch) || 0
    };

    // Calculate totalScore
    let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
        return acc + (scores[criteria.name] * weight);
    }, 0);

    totalScore = +(totalScore?.toFixed(2)); // Round to two decimal places

    useEffect(() => {
        if (onBoarding) {
            document.documentElement.style.setProperty('--dropdown-border', onBoarding.color || 'transparent');
        }
    }, [onBoarding]);

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [decision, setdecision] = useState(value?.role);
    const handleDecisionChange = async (value, id) => {
        setdecisionLoading(true);
        try {
            console.log(value);

        } catch (error) {
            setdecisionLoading(false);
            console.error("An error occurred while updating the decision:", error);
            // Handle the error appropriately, e.g., show an error message to the user
        } finally {
            setdecisionLoading(false);
        }
    };

const handleDelete = async (inviteId, isAccepted, userId) => {
    const body = {
        status: false,
        isAccepted: false,
    }

   
  try {
    if(!isAccepted && !userId) {
        const response = await backend.delete(`/api/invite/${inviteId}`);
        if (response.status === 200) {
            console.log('Invite deleted successfully:', response.data.message);
            await dispatch(updateDeckInfo(response?.data))
          }
    } else {
        const response = await backend.put(`/api/invite/${inviteId}`, body);
        if (response.status === 200) {
            console.log('Invite deleted for available users successfully:', response.data.message);
            await dispatch(updateDeckInfo(response?.data))
          }
          const userResp = await backend.put(`/api/user/${userId}`, {status: "disabled"});
          if (response.status === 200) {
          console.log('Invite disabled successfully:', userResp.data);
          await dispatch(updateDeckInfo(response?.data))

          }

    }

    
  } catch (error) {
    console.error('Error deleting invite:', error.response?.data?.message || error.message);
  }
};

    return loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
    </div> : (
        <>
            {openEmailSentPopup && <EmailSuccessPopup closePopup={handleCloseEmailSentPopup}
                title="WOOHOO!"
                description="Your email has been sent successfully."
                buttonText="Continue screening"
            />}
            <div className={Classes.row} >
                <div className={Classes.rowHeader} >
                    <h5>{value?.userId?.fullname??"pending"}</h5>
                    <h5>{value?.inviteeEmail}</h5>
                    
                    {
                        value?.isAccepted?
                    <div className={Classes.dropDown} >
                        {/* Dropdown Head */}
                        <div
                            className={Classes.dropDownHead}
                            onClick={() => setdropDown(!dropDown)}
                            ref={dropdownRef}
                        >
                            <p style={{ textTransform: 'capitalize' }} >{decision === 'Admin' ? 'Admin' : decision === 'Scorer' ? 'Scorer' : `${value?.role=="judge"?"Scorer":"Admin"}`}</p>
                            <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }} />
                        </div>

                        {/* Dropdown Body */}
                        <AnimatePresence>
                            {dropDown && (
                                <motion.div
                                    initial={{ opacity: 0, y: -5 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -5 }}
                                    transition={{
                                        duration: 0.15,
                                    }}
                                    className={Classes.dropDownBody}
                                >
                                    <div className={Classes.ddPointerCon}>
                                        <div className={Classes.dropDownPointer}></div>
                                    </div>
                                    <div className={Classes.dropDownBodyInner}>
                                        {/* Dropdown Options */}
                                        <div
                                            className={`${Classes.dropDownElem} ${decision === 'Admin' ? Classes.activeDropElem : ''}`}
                                            onClick={() => handleDecisionChange('Admin', value?._id)}
                                            style={{ padding: '5px 10px' }} // Mimic MUI padding style
                                        >
                                            <h5>Admin</h5>
                                        </div>
                                        <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                                        <div
                                            className={`${Classes.dropDownElem} ${decision === 'Scorer' ? Classes.activeDropElem : ''}`}
                                            onClick={() => handleDecisionChange('Scorer', value?._id)}
                                            style={{ padding: '5px 10px' }}
                                        >
                                            <h5>Scorer</h5>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        {/* Hidden input for accessibility */}
                        <input
                            type="hidden"
                            value={decision}
                            aria-label="Without label"
                            readOnly
                        />
                    </div>:<div><h5>pending</h5></div> }
                    <div>
                        <div className={`${Classes.status} ${value?.isAccepted === false ? Classes.pendingStatus : value?.isAccepted === true ? Classes.activeStatus : ''} `} >
                            <h5> {value?.isAccepted ?"Active" : 'Pending Invitation'} </h5>
                        </div>
                    </div>
                    <div>
                        <h5 className={Classes.removeTxt} onClick={()=>handleDelete(value._id, value.isAccepted, value?.userId?._id)}>Remove</h5>
                    </div>
                </div>
            </div >
        </>
    )
}
