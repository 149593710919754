import React, { useState, useEffect, useRef } from 'react';
import Classes from './Team.module.css';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import EmailSuccessPopup from '../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';

export default function InviteByEmailComp({ onInvite, decision, setdecision, inviteeEmail, setInviteeEmail }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [dropDown, setdropDown] = useState(false);
    const [openEmailSentPopup, setopenEmailSentPopup] = useState(false);
    
    const dropdownRef = useRef(null);

    const handleClosedropdown = () => setdropDown(false);
    const handleCloseEmailSentPopup = () => setopenEmailSentPopup(false);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    
    const handleInvite = () => {
        // setopenEmailSentPopup(true);
        if (onInvite) onInvite();  // Trigger the parent component's function if provided
    };

    return (
        <>
            {/* <AnimatePresence exit="wait">
                {openEmailSentPopup && (
                    <EmailSuccessPopup
                        closePopup={handleCloseEmailSentPopup}
                        title="WOOHOO!"
                        description="Your email has been sent successfully."
                        buttonText="Return to dashboard"
                    />
                )}
            </AnimatePresence> */}
            <div className={Classes.inviteMain}>
                <input type="text" placeholder="Invite by email" value={inviteeEmail} onChange={setInviteeEmail}/>
                <div className={Classes.dropDown} ref={dropdownRef} >
                    <div
                        className={Classes.dropDownHead2}
                        onClick={() => setdropDown(!dropDown)}
                    >
                        <p style={{ textTransform: 'capitalize' }}>
                            {decision === 'Admin' ? 'Admin' : decision === 'Scorer' ? 'Scorer' : 'Select'}
                        </p>
                        <img
                            src="/onBoarding/arrow-down.svg"
                            alt="delete"
                            style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }}
                        />
                    </div>
                    <AnimatePresence>
                        {dropDown && (
                            <motion.div
                                initial={{ opacity: 0, y: -5 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -5 }}
                                transition={{ duration: 0.15 }}
                                className={Classes.dropDownBody}
                            >
                                <div className={Classes.ddPointerCon}>
                                    <div className={Classes.dropDownPointer}></div>
                                </div>
                                <div className={Classes.dropDownBodyInner}>
                                    <div
                                        className={`${Classes.dropDownElem} ${decision === 'Admin' ? Classes.activeDropElem : ''}`}
                                        onClick={() => setdecision('Admin')}
                                        style={{ padding: '5px 10px' }}
                                    >
                                        <h5>Admin</h5>
                                    </div>
                                    <div className={Classes.dropDownLine}></div>
                                    <div
                                        className={`${Classes.dropDownElem} ${decision === 'Scorer' ? Classes.activeDropElem : ''}`}
                                        onClick={() => setdecision('Scorer')}
                                        style={{ padding: '5px 10px' }}
                                    >
                                        <h5>Scorer</h5>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <input type="hidden" value={decision} aria-label="Without label" readOnly />
                </div>
                <button style={{ background: onBoarding?.color }} onClick={handleInvite}>
                    <h5>Invite</h5>
                </button>
            </div>
        </>
    );
}
